import React from "react";
import Header from "../components/header";
import Container from "../components/container";
import * as Styles from "./about.module.css";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <Layout hasFooter={true}>
      <Helmet>
        <title>About Tom Laker</title>
        <meta
          name="description"
          content="Tom Laker is a graphic designer and photographer based in Stockholm. Originating from the South coast of England Tom has lived and worked all over the world, from Hong Kong to Australia and now Sweden. His work has been shaped by the environments he’s lived and by the strong morals he holds."
        />
      </Helmet>
      <Header light={false} />
      <main className={Styles.wrapper}>
        <Container>
          <div className={Styles.content}>
            <p>
              Tom Laker is a creative technologist (design, photography, code)
              based in Stockholm. Originating from the South coast of England
              Tom has lived and worked all over the world, from Hong Kong to
              Australia and now Sweden. His work has been shaped by the
              environments he’s lived and by the strong morals he holds.
            </p>
            <p>
              Tom’s approach is to build long lasting relationships with his
              clients through strong communication, collaboration and beautiful
              designs. From managing a team of ten, to working solo on projects
              from conception to delivery, Tom has experienced all sides of the
              design conversation and that has given him the maturity and
              expertise to deliver.
            </p>
            <p>
              With over a decade of professional experience across the whole
              spectrum of design and being traditionally taught in graphic
              design at Falmouth University, Tom doesn’t focus on one aspect of
              design and creativity but uses the medium as a way to tell stories
              and show the beauty in the world.{" "}
            </p>
            <p>
              This passionate interest in all areas of graphic design has
              allowed him to work across multiple disciplines and industries,
              applying his trade as the situation demands. Tom believes no
              matter the industry or environment, there’s always room to make
              positive change.{" "}
            </p>
            <p>
              Tom currently resides in Stockholm with his partner and their
              young child.
            </p>
          </div>
          <StaticImage
            src="../images/about.jpg"
            className={Styles.image}
            alt="Tom Laker at an art shop in Japan doing tranditional japanese painting."
          />
        </Container>
        <div className={Styles.lists}>
          <Container>
            <div className={Styles.heading}>Services</div>
            <ul className={Styles.list1}>
              <li>Art direction</li>
              <li>Product design</li>
              <li>Product development</li>
              <li>Web design</li>
              <li>Web development</li>
              <li>App design</li>
              <li>Visual identity</li>
              <li>Digital design</li>
            </ul>
            <ul className={Styles.list2}>
              <li>Branding</li>
              <li>User exeperience</li>
              <li>User interface</li>
              <li>Photography</li>
              <li>Video</li>
              <li>Moving image</li>
              <li>Illustration</li>
            </ul>
          </Container>
        </div>
        <div className={Styles.lists}>
          <Container>
            <div className={Styles.heading}>Publications</div>
            <ul className={Styles.list1}>
              <li>Sidetracked</li>
            </ul>
            <ul className={Styles.list2}>
              <li>Camp</li>
            </ul>
          </Container>
        </div>
        <div className={Styles.footer}>say hello</div>
      </main>
      {/* <Footer /> */}
    </Layout>
  );
};

export default AboutPage;
